<template>
  <div>

    <BannerVideo :video="video"></BannerVideo>

    <Details :video="video" />






    <div class="mt-4"></div>


    <Items v-for="(category, key) in categories" :category="category" :categoryID="key" :key="key" />




  </div>
</template>




<script>
import { core } from '../../config/pluginInit'
import Details from './MovieDetailPage/Detail'
import BannerVideo from './MovieDetailPage/BannerVideo'
import Items from './Components/ListItems/ListItems'
import ApiService from '../../services/api'
import AlertServie from '../../services/errors'
export default {
  name: 'MovieDetail',
  components: {
    Details,
    Items,
    BannerVideo
  },
  data() {
    return {
      video: {},
      categories: [],
      ContentID: this.$route.params.contentID
    }
  },
  methods: {
    openContent() {
      ApiService.openContent(this.$route.params.contentID).then((response) => {
        this.$store.commit('setPoints', response.data.user_points)
        this.video = response.data.data
        if (this.video.content_url[this.$i18n.locale] == null) {
          if(this.$i18n.locale == 'en'){
            AlertServie.info("This content is not available in your language")
          }else if(this.$i18n.locale == 'ar'){
            AlertServie.info("هذا المحتوى غير متوفر بلغتك")
          }else{
            AlertServie.info("ئەم ناوەڕۆکە بۆ زمانەکەت نییە")
          }
        }
      })
    },
    async getContentCategories() {
     await ApiService.getLastContentCategories().then(async(response) => {
        this.categories = response.data.data
      })
    },
    aletwithLang() {
      if (this.$i18n.locale === 'en') {
        AlertServie.info("to rewatch this content go to \"My Contents\"")
      } else if (this.$i18n.locale === 'ar') {
        AlertServie.info("لإعادة مشاهدة هذا المحتوى انتقل إلى (محتوياتي)")
      } else {
        AlertServie.info("بۆ دووبارە سەیرکردنی ئەم ناوەڕۆکە بڕۆ بۆ (لیستەکەم)")
      }
    }
  },
  mounted() {
    core.index()
    this.openContent()
    setTimeout(() => {
      this.getContentCategories()
    }, 300)
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.contentID !== from.params.contentID && from.name !== 'landing-page') {
      this.aletwithLang()
    }
    next()
  }
}
</script>
