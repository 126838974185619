<template>
  <div>
    <div
      class="video-container iq-main-slider mt-2"
      v-if="video.content_url?.[$i18n.locale] != null"
    >
      <br />

      <div v-if="video.content_type === 'Image'">
        <div
          :class="{
            active: showImagePopup,
            disabled: !showImagePopup,
            imagePopup: true,
          }"
          id="imagePopup"
        >
          <div @click="closeContent">
            <i class="fa fa-times closeBtn"></i>
          </div>
          <img :src="imageContentPath" alt="Image" />
        </div>

        <div class="image-container">
          <img
            :src="video.content_url?.[$i18n.locale]"
            alt="Image"
            @click.prevent="openContent(video.content_url?.[$i18n.locale])"
          />
        </div>
      </div>

      <div v-if="video.content_type === 'Video'">
        <vue-plyr :options="options">
          <video controls>
            <source
              size="720"
              :src="video.content_url[$i18n.locale]"
              type="video/mp4"
            />
          </video>
        </vue-plyr>
      </div>
    </div>
    <div
      class="video-container iq-main-slider"
      v-show="video.content_url?.[$i18n.locale] == null"
    ></div>
  </div>
</template>
<script>
import AlertServie from "../../../services/errors"
import VuePlyr from "vue-plyr"
import "vue-plyr/dist/vue-plyr.css"
export default {
  name: "BannerVideo",
  props: ["video"],

  data() {
    return {
      activeTab: "images",
      options: { quality: { default: "1080p" } },
      showImagePopup: false,
      imageContentPath: "",
      favOption: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        prevArrow:
          '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
        nextArrow:
          '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    }
  },

  components: {
    VuePlyr,
  },
  mounted() {
    var videoPlayer = document.getElementById("videoPlayer")

    // Add an event listener to detect when the video ends
    videoPlayer.addEventListener("ended", function () {
      // Send a message to the parent page indicating that the video has ended
      AlertServie.info('to rewatch this content go to "My Contents"')
    })
  },

  methods: {
    closeContent() {
      this.imageContentPath = ""
      this.showImagePopup = false
      //  this.imagePopup = false
    },
    openContent(imageurl) {
      this.showImagePopup = true
      var self = this
      self.imageContentPath = imageurl
    },
  },
}
</script>

<style scoped lang="scss">
.imagestyle {
  transform: translate(0%, 0%);
  // @media (min-width: 1000px) and (max-width: 3000px) {

  //   transform: translate(100%, 10%);

  // }
}

.image-container {
  width: 700px;
  height: 500px;
  margin: 0 auto;
}

.image-container img {
  object-fit: fill;
}

@media (max-width: 600px) {
  .image-container {
    width: auto;
    height: auto;
  }
}

.imagePopup.active,
.textPopup.active {
  display: block;
  z-index: 999;
}

.imagePopup {
  max-height: 80vh;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: 80% !important;
  //  max-height: 80vh;
  aspect-ratio: 1/1;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(96vw);
    height: fit-content;
  }

  @media (min-width: 768px) {
    width: fit-content;
    height: fit-content;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
    font-weight: 1200;
    font-size: 40px;
    color: #ddd;
  }

  .prev {
    left: 20px;
    transform: translateY(-50%);
  }

  .next {
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    z-index: 999;
    overflow: hidden;
    cursor: pointer;
  }
}

.main-title {
  font-size: 22px;
  align-items: left;
  padding-right: 1240px;
  padding-top: 100px;
}

.imagePopup.active,
.textPopup.active {
  display: block;
  z-index: 999;
}

.imagePopup {
  max-height: 80vh;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: fit-content;
  //  max-height: 80vh;
  aspect-ratio: 1/1;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(96vw);
    height: fit-content;
  }

  @media (min-width: 768px) {
    width: fit-content;
    height: fit-content;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
    font-weight: 1200;
    font-size: 40px;
    color: #ddd;
  }

  .prev {
    left: 20px;
    transform: translateY(-50%);
  }

  .next {
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    z-index: 999;
    overflow: hidden;
    cursor: pointer;
  }
}

.tabs {
  display: flex;
}

button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

button.active {
  background-color: #a02278;
  color: #f0f0f0;
}

.tab-content {
  margin-top: 20px;
}
</style>
